<template>
  <div
    :id="'apollo_4d_graph' + widgetId"
    :style="{
      width: this.widgetWidth,
      height: this.widgetHeight,
      zIndex: 9999,
    }"
    @contextmenu="this.setContextIdWidget" class="shadow-lg"
  >
    <span class="hidden">{{ darkDark }}</span>
    <!-- Plotly chart will be drawn inside this DIV -->
  </div>
</template>

<script>
import { getLogname } from "../../../js/common.js";
import apiService from "../../../api/services";
import { newPlot } from "plotly.js-dist-min";
import { useWellLogs } from "../../../composable/useWellLogs";
export default {
  name: "3dGraphWidget",
  components: {},
  props: {
    openConfig: Function,
    graphWidgetConfig: Object,
  },
  data() {
    return {
      idx: -1,
      widgetWaitTimer: true,
      wellId: "",
      wellboreId: "",
      logId: "",
      wellboreState: "",
      logType: "",
      traces: {},
      tracesText: {},
      // mnemonicTags: ["lstk", "spm1", "tva", "drtm"],
      mnemonicTags: [
        this.mnemonicTag1,
        this.mnemonicTag2,
        this.mnemonicTag3,
        this.mnemonicTag4,
      ],
      table: "",
      logs:null
    };
  },
  props: {
    displayId: String,
    widgetiD: String,
    widgetHeight: {
      type: String,
      default: "200",
    },
    widgetWidth: {
      type: String,
      default: "350",
    },
    gridColor: {
      type: String,
      default: "white",
    },
    backgroundColor: {
      type: String,
      default: "rgb(200, 200, 230)",
    },
    graphMnemonics: {
      type: Array,
      default: ["lstk", "spm1", "tva", "drtm"],
    },
    verticalcardBG: {
      default: "#FFFFF7",
    },
    lightThemeDarkColor: {
      default: "black",
    },
    mnemonicTag1: String,
    mnemonicTag2: String,
    mnemonicTag3: String,
    mnemonicTag4: String,
    widgetId: String,
  },
  computed: {
    darkDark() {
      // if (this.$store.state.rect.darkmode !== 'white') {
      //   this.$store.dispatch("rect/changeBackgroundColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: this.backgroundColor || "#c8c8e6",
      //   });
      //   this.$store.dispatch("rect/changeColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: this.gridcolor || "white",
      //   });
      //   // valueColor
      // } else {
      //   this.$store.dispatch("rect/changeBackgroundColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: this.backgroundColor || "white",
      //   });
      //   this.$store.dispatch("rect/changeColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: this.gridcolor || "black",
      //   });
      // }
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
    async load3dGraph() {
      let displayDtls = this.$store.state.disp.displays[this.displayId];
      if (displayDtls) {
        this.wellId = displayDtls.wellId;
        this.wellboreId = displayDtls.wellboreId;
        this.logId = displayDtls.logId;
        this.wellboreState = displayDtls.wellboreState;
        this.logType = displayDtls.logType;
      }

      let tagName = this.mnemonicTags;
      let graphData = {};
      // let tagName = this.mnemonicTags.map((tag) => tag.toUpperCase());
      let result = {
        columns: ["default0", "default1", "default2", "default3"],
        data: { dmea: [0, 0, 0, 0] },
      };
      console.log(" 4d logs : before filter : ", tagName);
      let checkTagName = tagName.filter(function (element) {
        return (
          element !== undefined &&
          element.toLowerCase().indexOf("default") === -1 &&
          element !== ""
        );
      });
      console.log(" 4d logs : after filter : ", checkTagName);
      if (checkTagName && checkTagName.length) {
        try {
          result = await this.getSingleTagData(tagName);
        } catch (error) {
          console.error("error getting in single tag data : ", error);
        }
      } else {
        // tagName = ["default0", "default1", "default2", "default3"];
        // this.mnemonicTags = tagName;
      }

      // tagName = tagName.map((tag) => tag.toLowerCase());

      let tracesText = {
        trace1: [],
      };
      tagName[0]
        ? (graphData[tagName[0].toUpperCase()] = [])
        : (graphData["DEFAULT0"] = []);
      tagName[1]
        ? (graphData[tagName[1].toUpperCase()] = [])
        : (graphData["DEFAULT1"] = []);
      tagName[2]
        ? (graphData[tagName[2].toUpperCase()] = [])
        : (graphData["DEFAULT2"] = []);
      tagName[3]
        ? (graphData[tagName[3].toUpperCase()] = [])
        : (graphData["DEFAULT3"] = []);

      let indexTag0, indexTag1, indexTag2, indexTag3;

      if (result.data && result.data["dmea"])
        result.data["dmea"].map((data, index) => {
          let iter_string = "";
          iter_string +=
            tagName[0].toUpperCase().indexOf("DEFAULT") == -1
              ? tagName[0].toUpperCase()
              : "Y";
          iter_string += " : ";
          iter_string +=
            result.data[tagName[0]] && result.data[tagName[0]][index]
              ? result.data[tagName[0]][index]
              : "0";
          iter_string += "<br>";
          iter_string +=
            tagName[1].toUpperCase().indexOf("DEFAULT") == -1
              ? tagName[1].toUpperCase()
              : "X";
          iter_string += " : ";
          iter_string +=
            result.data[tagName[1]] && result.data[tagName[1]][index]
              ? result.data[tagName[1]][index]
              : "0";
          iter_string += "<br>";
          iter_string +=
            tagName[2].toUpperCase().indexOf("DEFAULT") == -1
              ? tagName[2].toUpperCase()
              : "Z";
          iter_string += " : ";
          iter_string +=
            result.data[tagName[2]] && result.data[tagName[2]][index]
              ? result.data[tagName[2]][index]
              : "0";
          iter_string += "<br>";
          iter_string +=
            tagName[3].toUpperCase().indexOf("DEFAULT") == -1
              ? tagName[3].toUpperCase()
              : "VALUE AXIX";
          iter_string += " : ";
          iter_string +=
            result.data[tagName[3]] && result.data[tagName[3]][index]
              ? result.data[tagName[3]][index]
              : " 0";

          tracesText.trace1.push(iter_string);

          tagName[0].toLowerCase().indexOf("default0") === -1
            ? result.data[tagName[0]]
              ? graphData[tagName[0].toUpperCase()].push(
                  result.data[tagName[0]][index]
                )
              : 0
            : graphData["DEFAULT0"]
            ? graphData["DEFAULT0"].push(0)
            : graphData[tagName[0].toUpperCase()].push(0);
          tagName[1].toLowerCase().indexOf("default1") === -1
            ? result.data[tagName[1]]
              ? graphData[tagName[1].toUpperCase()].push(
                  result.data[tagName[1]][index]
                )
              : 0
            : graphData["DEFAULT1"]
            ? graphData["DEFAULT1"].push(0)
            : graphData[tagName[1].toUpperCase()].push(0);
          tagName[2].toLowerCase().indexOf("default2") === -1
            ? result.data[tagName[2]]
              ? graphData[tagName[2].toUpperCase()].push(
                  result.data[tagName[2]][index]
                )
              : 0
            : graphData["DEFAULT2"]
            ? graphData["DEFAULT2"].push(0)
            : graphData[tagName[2].toUpperCase()].push(0);
          tagName[3].toLowerCase().indexOf("default3") === -1
            ? result.data[tagName[3]]
              ? graphData[tagName[3].toUpperCase()].push(
                  result.data[tagName[3]][index]
                )
              : 0
            : graphData["DEFAULT3"]
            ? graphData["DEFAULT3"].push(0)
            : graphData[tagName[3].toUpperCase()].push(0);
        });
      this.tracesText = tracesText;

      this.traces = { trace1: graphData };
      this.plotly({ trace1: graphData });
    },
    plotly(traces) {
      let widgetWidth = this.widgetWidth;
      let widgetHeight = this.widgetHeight;
      let gridColor = this.gridColor;
      let backgroundColor = this.backgroundColor;
      let tracesText = this.tracesText;
      let memonics = this.mnemonicTags.map((tags) => tags.toUpperCase());
      let tags = [];
      if (this.$store.state.disp.displays[this.displayId])
        tags = this.$store.state.disp.displays[this.displayId].tags;

      let count = 1;

      let defrentiator = "ROPA";

      var trace1 = {
        x: traces.trace1[memonics[0]],
        y: traces.trace1[memonics[1]],
        z: traces.trace1[memonics[2]],
        mode: "markers",
        showlegend: false,
        marker: {
          size: 2,
          symbol: "point",
          // line: {
          //   color: traces.trace1[memonics[3]],
          //   autocolorscale: true
          // },
          opacity: 0.8,
          showscale: true,
          colorscale: "Jet",
          color: traces.trace1[memonics[3]],
          // colorbar: {title: ""},
          colorbar: {
            title:this.mnemonicTag4!='DEFAULT3'?`${this.logs?.displayTags[this.logs?.tags.indexOf(this.mnemonicTag4)]} (${this.logs?.units[this.logs?.tags.indexOf(this.mnemonicTag4)]})`:'',
            x: 1,
            len: 0.9,
            thickness: 15,
            titlefont: { color: this.darkDark !== 'white' ? "white" : "black", size: 10 },
            tickfont: { color: this.darkDark !== 'white' ? "white" : "black", size: 10 },
          },
        },

        name: "ROPA < 50",
        type: "scatter3d",
        // name: "ROPA > 50 AND ROPA < 100",
        text: tracesText.trace1,
        hoverinfo: "text",
      };
      count++;

      var data = [trace1];

      // let plotlyThemeBg = this.darkDark !== 'white' ? this.darkDark== 'blue' ? "#1e293b" : "#3f3f3f" : this.verticalcardBG;
      let themeBg = getComputedStyle(document.documentElement).getPropertyValue('--navBar2Bg')
      let plotlyThemeBg =themeBg
      var layout = {
        paper_bgcolor: plotlyThemeBg,
        // plot_bgcolor: "rgba(0,0,0,0)",
        width: widgetWidth,
        height: widgetHeight,
        showlegend: false,
        scene: {
          showlegend: false,
          camera: {
            center: { x: 0, y: 0, z: 0 },
            eye: { x: 1.5, y: 1.5, z: 1.5 },
            up: { x: 0, y: 0, z: 1 },
          },
          aspectratio: { x: 0.8, y: 0.8, z: 0.8 },
          xaxis: {
            title:this.mnemonicTag2!='DEFAULT1'?`${this.logs?.displayTags[this.logs?.tags.indexOf(this.mnemonicTag2)]} (${this.logs?.units[this.logs?.tags.indexOf(this.mnemonicTag2)]})`:'',
            titlefont: {
              size: 10,
            },
            color: this.darkDark !== 'white' ? "white" : "black",
            tickfont: {
              color: this.darkDark !== 'white' ? "white" : "black",
              size: 10,
            },
            backgroundfixed: true,
            backgroundcolor: backgroundColor,
            autorange: "reversed",
            gridcolor: gridColor,
            gridwidth: 2,
            showbackground: true,
            zerolinecolor: "rgb(255, 255, 255)",
            fixedrange: true,
          },
          yaxis: {
            title:this.mnemonicTag3!='DEFAULT2'?`${this.logs?.displayTags[this.logs?.tags.indexOf(this.mnemonicTag3)]} (${this.logs?.units[this.logs?.tags.indexOf(this.mnemonicTag3)]})`:'',
            titlefont: {
              size: 10,
            },
            color: this.darkDark !== 'white' ? "white" : "black",
            tickfont: {
              color: this.darkDark !== 'white' ? "white" : "black",
              size: 10,
            },
            backgroundcolor: backgroundColor,
            gridcolor: gridColor,
            gridwidth: 2,
            showbackground: true,
            zerolinecolor: "rgb(255, 255, 255)",
            fixedrange: true,
          },

          zaxis: {
            title:this.mnemonicTag1!='DEFAULT0'?`${this.logs?.displayTags[this.logs?.tags.indexOf(this.mnemonicTag1)]} (${this.logs?.units[this.logs?.tags.indexOf(this.mnemonicTag1)]})`:'',
            titlefont: {
              size: 10,
            },
            color: this.darkDark !== 'white' ? "white" : "black",
            tickfont: {
              color: this.darkDark !== 'white' ? "white" : "black",
              size: 10,
            },
            autorange: "reversed",
            backgroundcolor: backgroundColor,
            gridcolor: gridColor,
            gridwidth: 2,
            showbackground: true,
            zerolinecolor: "rgb(255, 255, 255)",
            fixedrange: true,
          },
        },
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0,
        },
      };
      newPlot("apollo_4d_graph" + this.widgetId, data, layout, {
        displayModeBar: false,
        fixedrange: true,
        showTips: false
      });
      // });
    },
    async getSingleTagData(tagName) {
      let result = null;
      let dconfig = {};
      dconfig.fields = tagName;
      dconfig.wellId = this.wellId;
      dconfig.wellboreId = this.wellboreId;
      dconfig.logId = this.logId;
      dconfig.dataPoints = null;
      dconfig.depthRange = null;
      dconfig.logName = getLogname(this.logType);
      tagName = tagName.filter(function (element) {
        return (
          element !== undefined &&
          element.toLowerCase().indexOf("default") === -1 &&
          element !== ""
        );
      });
      if (this.logType == "depth") {
        result = await apiService.WellServices.getDephtLogs({
          fields: tagName,
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
        });
      } else {
        this.$toast.error(`4D WIDGET ONLY WORKS FOR DEPTH LOG`, {
          duration: "3000",
          position: "top",
          pauseOnHover: true,
        });
        result = {
          columns: ["default0", "default1", "default2", "default3"],
          data: { dmea: [0, 0, 0, 0] },
        };
      }

      result = result.data;
      return result;
    },
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
  },
  watch: {
    darkDark(){
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
    mnemonicTag1: async function (newVal, oldVal) {
      // This is z axis.
      this.mnemonicTags[0] = newVal;

      this.load3dGraph();
    },
    mnemonicTag2: async function (newVal, oldVal) {
      this.mnemonicTags[1] = newVal;
      this.load3dGraph();
    },
    mnemonicTag3: async function (newVal, oldVal) {
      this.mnemonicTags[2] = newVal;
      this.load3dGraph();
    },
    mnemonicTag4: async function (newVal, oldVal) {
      this.mnemonicTags[3] = newVal;
      this.load3dGraph();
    },
    widgetHeight: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 500);
    },
    widgetWidth: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 500);
    },
    gridColor: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 500);
    },
    backgroundColor: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.widgetPropertyIndex = i;
      }
    }
    this.idx = this.widgetId;

    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];
    if (displayDtls) {
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.logId = displayDtls.logId;
      this.wellboreState = displayDtls.wellboreState;
      this.logType = displayDtls.logType;

      this.table = displayDtls.table;
    }
  },
  async mounted() {
    if(this.$store.state.disp.displays[this.displayId]?.wellId){
      this.logs = await this.getLogs(
        this.$store.state.disp.displays[this.displayId].wellId,
        "depth"
      );
    }
    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type == "data/setSingleValue") {
        let display = this.$store.state.disp.displays[this.displayId];

        let valueAt = "end";
        let name =
          this.title +
          "-" +
          this.displayId +
          "-" +
          display.logId +
          "-" +
          valueAt;

        let plName =
          mutation.payload.title +
          "-" +
          mutation.payload.display +
          "-" +
          mutation.payload.logId +
          "-" +
          mutation.payload.valueAt;

        if (name == plName) {
          this.niddleAngle = mutation.payload.value;
        }
      }
      if (mutation.type == "disp/setDisplay") {
        if (this.displayId == mutation.payload.display) {
          if(this.$store.state.disp.displays[this.displayId]?.wellId){
            this.logs = await this.getLogs(
              this.$store.state.disp.displays[this.displayId].wellId,
              "depth"
            );
          }
          this.wellId = mutation.payload.wellId;
          this.wellboreId = mutation.payload.wellboreId;
          this.logId = mutation.payload.logId;
          this.wellboreState = mutation.payload.wellboreState;
          this.logType = mutation.payload.logType;

          this.table = mutation.payload.table;
          // let displayDtls = this.$store.state.disp.displays[this.displayId];
          if (this.wellboreState == "active") {
            // this.widgetTitle = this.title;
            this.defaultTitle = this.title;
          } else {
            this.defaultTitle = this.title;
          }
          if (this.displayId == mutation.payload.display) this.load3dGraph();
        }
      }
      if (mutation.type == "data/displayChanged") {
        let valueAt = "end";

        let name =
          this.title + "-" + this.displayId + "-" + this.logId + "-" + valueAt;
      }
    });

    this.load3dGraph();
    document.querySelector('.main-svg').style.transition = 'var(--transition)'
  },
  setup() {
    const { getLogs } = useWellLogs();
    return {
      getLogs,
    };
  },
};
</script>

